import { render, staticRenderFns } from "./SerialAuth.html?vue&type=template&id=52e844e2&scoped=true&external"
import script from "./SerialAuth.js?vue&type=script&lang=js&external"
export * from "./SerialAuth.js?vue&type=script&lang=js&external"
import style0 from "./SerialAuth.scss?vue&type=style&index=0&id=52e844e2&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e844e2",
  null
  
)

export default component.exports